import dayjs, { Dayjs } from 'dayjs'
import { Article, WithContext } from 'schema-dts'

import nbsp from './remove-hanging-conjunctions'

import { BannerWithTextAndCtaProps } from '@/components/BannerWithTextAndCta.vue'
import {
  PublicArticleDetail,
  PublicArticleListWidget,
  PublicImage,
  PublicPodcastDetail
} from '@/service/__generated-api'

/**
 * Extracts single element from list or return element itself
 * @param elmOrArray
 */

function flatten<T>(elmOrArray: T | T[]): T {
  return Array.isArray(elmOrArray) ? elmOrArray[0] : elmOrArray
}

/**
 * Converts empty string or null to undefined
 * @param str
 */

function emptyToUndefined(str?: string | null): string | undefined {
  return !str || str.length === 0 ? undefined : str
}

/**
 * Repeats an array until the resulting array's
 * length is equal or greater than `minItems`
 * @param items
 * @param minItems
 */

function getItemsForLoop<T>(items: T[], minItems: number) {
  const { length } = items
  const repeats = length >= minItems ? 1 : Math.ceil(minItems / length)
  return Array.from({ length: repeats * length }, (_, i) => items[i % length])
}

/**
 * Format widget data to match BannerWithTextAndCtaProps
 * @param widget
 */

function getBannerProps(
  widget: PublicArticleDetail | PublicPodcastDetail | PublicArticleListWidget
): BannerWithTextAndCtaProps {
  return {
    title: widget.banner_title,
    ctaButton: widget.banner_cta_button,
    image: widget.banner_background,
    text: widget.banner_text,
    fullWidth: widget.banner_full_width,
    whiteText: widget.banner_with_white_text
  }
}

/**
 * Extracts text from html
 * @param html
 */

function stripTags(html: string) {
  return html.replace(/<\/?[^>]+(>|$)/g, '')
}

/**
 * Gets random number in range
 * @param min
 * @param max
 */
function getRandomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min
}

/**
 * Get Schema.org data for a blog post
 * @param page
 */

function getBlogPostLD(page: PublicArticleDetail | PublicPodcastDetail) {
  const schema: WithContext<Article> = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: page.title,
    image: page.image.file,
    datePublished: page.publication_date || undefined,
    keywords: page.meta_keywords,
    description: page.meta_description || page.og_description,
    abstract: stripTags(page.lead)
  }

  if (page.author) {
    schema.author = {
      '@type': 'Person',
      name: page.author.name,
      image: page.author.image.file,
      description: stripTags(page.author.bio),
      jobTitle: page.author.position
    }
  }

  return JSON.stringify(schema)
}

function getAspectRatioStyle(image: PublicImage) {
  return image.width && image.height
    ? { aspectRatio: `${image.width}/${image.height}` }
    : {}
}

/**
 * Convert date string to Dayjs object, handle timezone
 * @param dateString
 * @param timezoned
 */

function getDateObject(dateString: string, timezoned = true) {
  return timezoned ? dayjs(dateString).tz('Poland') : dayjs(dateString)
}

type DatetimeFormat =
  | 'D.MM'
  | 'YYYY'
  | 'HH:mm'
  | 'D.M.YYYY'
  | 'Do MMMM'
  | 'dddd D.MM'

/**
 * Format dayjs object to one of project's formats
 * @param date
 * @param format
 */

function formatDateObject(date: Dayjs, format: DatetimeFormat) {
  return date.format(format)
}

/**
 * Format datetime string, handle timezone
 * @param dateString
 * @param format
 * @param timezoned
 */

// eslint-disable-next-line max-params
function formatDate(
  dateString: string,
  format: DatetimeFormat,
  timezoned = true
) {
  return getDateObject(dateString, timezoned).format(format)
}

export {
  flatten,
  emptyToUndefined,
  getItemsForLoop,
  nbsp,
  getBannerProps,
  getBlogPostLD,
  stripTags,
  getRandomNumber,
  getAspectRatioStyle,
  getDateObject,
  formatDateObject,
  formatDate
}
